import { useQuery } from '@tanstack/react-query'

import { Role, Status } from '../models'
import { getAccountByUsername } from '../services'

export enum ACCOUNT_QUERY_KEYS {
  accountUser = 'account-user',
}

const useAccountByUsername = (username: string) => {
  const query = useQuery({
    queryKey: [ACCOUNT_QUERY_KEYS.accountUser, username],
    queryFn: getAccountByUsername,
    refetchOnWindowFocus: false,
    staleTime: 0,
    enabled: !!username,
  })

  return {
    record: query.data || {
      id: '',
      name: '',
      currentUser: {
        id: '',
        accountId: '',
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        confirmed: false,
        role: Role.owner,
        status: Status.inactive,
        confirmTokens: [],
      },
      users: [],
      funnels: [],
      targets: [],
    },
    refetch: query.refetch,
  }
}

export default useAccountByUsername
